
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		name: 'RadioBox',
	})
	export default class RadioBox extends Vue {
		@Prop({ default: 'md' }) readonly size: 'sm' | 'md' | 'lg';
		@Prop() readonly label: string;
		@Prop() readonly checked: boolean;
		@Prop() readonly name: string;
		@Prop() readonly value: string | boolean | null;
		get radioBoxClassName() {
			let className = `radio-box-container radio-box--${this.size}`;
			return className;
		}
		get RadioId() {
			return this.value ? `radio-box-id-${this.value}` : '';
		}
	}
